function baseArticlesListCtrl(articles, $scope, $location, $state, sourceCollection, view) {
  $scope.articles = articles;
  $scope.filters = {
    sourceCollection,
  };
  $scope.location = $location;
  $scope.total = articles.meta.total;

  const search = $location.search();

  if (search.content) {
    $scope.filters.content = search.content;
  }

  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.$watch("currentPage", function (page) {
    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });

  $scope.pageChanged = function pageChanged() {
    const limit = 20;
    const offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.articles.getList($scope.filters).then(function(articles) {
      $scope.articles = articles;
      $scope.total = articles.meta.total;
    });
  };

  $scope.editArticle = function (id) {
    $state.go(view, { articleId: id });
  };

  $scope.$on('filters.country', function(event, data) {
    $scope.filters.country = data;
    $scope.filters.offset = 0;   
    $scope.filters.limit = 20;
    $scope.currentPage = 1;

    $scope.articles.getList($scope.filters).then(function(articles) {
      $scope.currentPage = 1;
      $scope.articles = articles;
      $scope.total = articles.meta.total;
    });
  });
  $scope.$on('filters.name', function(event, data) {
    $scope.filters.city = data;
    $scope.filters.offset = 0;   
    $scope.filters.limit = 20;

    $scope.articles.getList($scope.filters).then(function(articles) {
      $scope.currentPage = 1;
      $scope.articles = articles;
      $scope.total = articles.meta.total;
    });
  });
}

'use strict';

/* exported
editBaseArticleCtrl
 */

function editBaseArticleCtrl($scope, article, sourceCollection) {
  $scope.article = article;

  $scope.article.paragraphs = article.paragraphs.map(p => {
    const updatedParagraph = Object.assign(p, {
        content: {
            value: p.content,
            edited: false
        },
        header: {
            value: p.header,
            edited: false
        }
    });

    if (p.subparagraphs && Array.isArray(p.subparagraphs)) {
        updatedParagraph.subparagraphs = p.subparagraphs.map(subp => ({
            content: {
                value: subp.content,
                edited: false
            },
            header: {
                value: subp.header,
                edited: false
            }
        }));
    }

    delete updatedParagraph.steps;
    return updatedParagraph;
});

  $scope.name = (article.city && article.city.city) || (article.country && article.country.name);

  $scope.updateContent = function(paragraph, parentParagraph) {
    paragraph.edited = true;
    paragraph.content.edited = true;
    paragraph.content.value = paragraph.content.value;
    paragraph.prompt = 'manual edit';
    
    if (parentParagraph) {
      parentParagraph.edited = true;
      parentParagraph.prompt = 'manual edit';
    }
  }

  $scope.updateHeader = function(paragraph, parentParagraph) {
    paragraph.edited = true;
    paragraph.prompt = 'manual edit';
    paragraph.header.edited = true;
    paragraph.header.value = paragraph.header.value;
    
    if (parentParagraph) {
      parentParagraph.edited = true;
      parentParagraph.prompt = 'manual edit';
    }
  }

  $scope.saveArticle = function() {
    const promise = article.put({ sourceCollection });

    promise
      .then(function() {
        $scope.$emit("notify", {
          type: "success",
          title: "Edit article",
          message: "Article was saved successfully",
        });
      })
      .catch(function(err) {
        $scope.$emit("notify", {
          type: "error",
          title: "Edit article",
          message: `Failed to edit article with err: ${err.message}`,
        });
      });
  }

  $scope.$on('editBaseArticle.save', function(event, data) {
    $scope.saveArticle();
  });
}

'use strict';

/* exported
editLpArticleCtrl
 */

function editLpArticleCtrl($scope, article, sourceCollection) {
  $scope.article = article;
  $scope.name = (article.city && article.city.city) || (article.country && article.country.name);

  $scope.saveArticle = function() {
    const promise = article.put({ sourceCollection });

    promise
      .then(function() {
        $scope.$emit("notify", {
          type: "success",
          title: "Edit article",
          message: "Article was saved successfully",
        });
      })
      .catch(function(err) {
        $scope.$emit("notify", {
          type: "error",
          title: "Edit article",
          message: `Failed to edit article with err: ${err.message}`,
        });
      });
  }

  $scope.$on('editLpArticle.save', function(event, data) {
    $scope.saveArticle();
  });
}

'use strict';

function menuEditArticleCtrl($scope, $rootScope, collection, $state) {
  $scope.save = function() {
    switch (collection) {
      case 'cities-content':
        $rootScope.$broadcast('editBaseArticle.save');
        break;
      case 'countries-content':
        $rootScope.$broadcast('editBaseArticle.save');
        break;
      case 'cities-lp-content':
        $rootScope.$broadcast('editLpArticle.save');
        break;
      case 'countries-lp-content':
        $rootScope.$broadcast('editLpArticle.save');
        break;
    
      default:
        break;
    }
  };

  $scope.goBack = function() {
    switch (collection) {
      case 'cities-content':
        $state.go('root.aiContent.aiCities');
        break;
      case 'countries-content':
        $state.go('root.aiContent.aiCountries');
        break;
      case 'cities-lp-content':
        $state.go('root.aiContent.aiLpCities');
        break;
      case 'countries-lp-content':
        $state.go('root.aiContent.aiLpCountries');
        break;
    
      default:
        break;
    }
};
}

function lpArticlesListCtrl(articles, $scope, $location, $state, appConfig, sourceCollection, view) {
  $scope.articles = articles;

  $scope.filters = {
    sourceCollection,
  };
  $scope.location = $location;
  $scope.total = articles.meta.total;

  const search = $location.search();

  if (search.content) {
    $scope.filters.content = search.content;
  }

  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.$watch("currentPage", function (page) {
    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });

  $scope.getLPLink = function(article) {
    switch (sourceCollection) {
      case 'cities-lp-content':
        return `${appConfig.frontendBrowserUrl}/routes/${article.city.country}/${article.city.slug}`;    
      case 'countries-lp-content':
        return `${appConfig.frontendBrowserUrl}/routes/${article.country.slug}`;    
      default:
        break;
    }
  }

  $scope.pageChanged = function pageChanged() {
    const limit = 20;
    const offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.articles.getList($scope.filters).then(function(articles) {
      $scope.currentPage = 1;
      $scope.articles = articles;
      $scope.total = articles.meta.total;
    });
  };

  $scope.editArticle = function (id) {
    $state.go(view, { articleId: id });
  };

  $scope.$on('filters.country', function(event, data) {
    $scope.filters.country = data;
    $scope.filters.offset = 0;   
    $scope.filters.limit = 20;

    $scope.articles.getList($scope.filters).then(function(articles) {
      $scope.currentPage = 1;
      $scope.articles = articles;
      $scope.total = articles.meta.total;
    });
  });
  $scope.$on('filters.name', function(event, data) {
    $scope.filters.city = data;
    $scope.filters.offset = 0;   
    $scope.filters.limit = 20;

    $scope.articles.getList($scope.filters).then(function(articles) {
      $scope.currentPage = 1;
      $scope.articles = articles;
      $scope.total = articles.meta.total;
    });
  });
}

'use strict';

/* exported
articlesListMenuCtrl
 */

function articlesListMenuCtrl($scope, $location, $rootScope) {
  $scope.filters = {};
  $scope.location = $location;

  var search = $location.search();

  if (search.country) {
    $scope.filters.country = {iso2: search.country};
  }

  if (search.slug) {
    $scope.filters.slug = search.slug;
  }
  if (search.country) {
    $scope.filters.country = search.country;
  }

  $scope.$watch('filters.country', function(country) {
    const countrySlug = country ? country.slug : '';
    $rootScope.$broadcast('filters.country', countrySlug);

    var search = $location.search();
    search.country = countrySlug;
    $location.search(search);
  });

  $scope.$watch('filters.name', function(name) {
    $rootScope.$broadcast('filters.name', name);

    var search = $location.search();
    search.name = name;
    $location.search(search);
  });

  $scope.reset = function() {
    $scope.filters = {};
    $rootScope.$broadcast('filters.country', null);
    $rootScope.$broadcast('filters.name', null);
    $location.search({});
  };
}
